import type { PageComponentType } from '@libs-components/types'

type Props = {
  pageComponents: PageComponentType.ResponseType['data']
}

const findFirstPageComponentBySlotKey = <K extends PageComponentType.ComponentKindsType>(
  pageComponents: Props['pageComponents'],
  slotKey: string,
) => {
  return pageComponents?.find(comp => comp.attributes.slotKey === slotKey) as
    | PageComponentType.DataType<K>
    | undefined
}

const getFilteredSlotKeyCustom = <K>(pageComponents: Props['pageComponents'], slotKey: string) => {
  return findFirstPageComponentBySlotKey(pageComponents, slotKey)?.attributes?.custom as
    | (K extends PageComponentType.ComponentKindsType ? PageComponentType.ComponentCustom<K> : K)
    | undefined
}

const useInit = ({ pageComponents }: Props) => {
  const recommendedProducts = getFilteredSlotKeyCustom<'picked_product_list'>(
    pageComponents,
    'recommended_product_list',
  )?.slice(0, 10)

  const limitedTimeOfferProducts = getFilteredSlotKeyCustom<'picked_product_list'>(
    pageComponents,
    'time_limited_product_list',
  )

  const latestCourses = getFilteredSlotKeyCustom<'picked_product_list'>(
    pageComponents,
    'latest_products',
  )

  const subscriptionProducts = getFilteredSlotKeyCustom<'picked_product_list'>(
    pageComponents,
    'smart_learning',
  )

  const ebookProducts = getFilteredSlotKeyCustom<'picked_product_list'>(
    pageComponents,
    'recommended_ebook_list',
  )

  const feedbacks = getFilteredSlotKeyCustom<'user_feedback'>(pageComponents, 'user_feedback') || []

  const floatCampaign = getFilteredSlotKeyCustom<'float_campaign'>(pageComponents, 'float_campaign')

  const latestEvents = getFilteredSlotKeyCustom<{
    items: { toUrl: string; imgUrl: string }[] | null
  }>(pageComponents, 'latest_events')?.items

  const brandInfo = findFirstPageComponentBySlotKey<'picked_brand_page_list'>(
    pageComponents,
    'picked_brand_page_list',
  )

  return {
    recommendedProducts,
    limitedTimeOfferProducts,
    latestCourses,
    subscriptionProducts,
    ebookProducts,
    feedbacks,
    floatCampaign,
    latestEvents,
    brandInfo,
  }
}

export default useInit
