import { isInClient, type Stage } from '../../env'

type SessionStorageOptions<Extra = {}> = { isStagedKey?: boolean } & Extra

type SessionStorageInstance = {
  get: <T = string | null>(key: string, options?: SessionStorageOptions<{ isJSON?: boolean }>) => T
  has: (key: string, options?: SessionStorageOptions) => boolean
  set: <T>(key: string, data: T, options?: SessionStorageOptions<{ isJSON?: boolean }>) => void
  remove: (key: string, options?: SessionStorageOptions) => void
  clearAll: (cb?: any) => void
  length: () => number
}

const createSessionStorageInstance = (stage: Stage): SessionStorageInstance => {
  const getKey = (key: string, options?: SessionStorageOptions) => {
    return options?.isStagedKey ? key : stage === 'production' ? key : `${stage}-${key}`
  }
  const isJSON = (options?: SessionStorageOptions<{ isJSON?: boolean }>) => {
    return !!options?.isJSON
  }

  // Core operations for session storage
  const instance: SessionStorageInstance = Object.freeze({
    get: (key, options) => {
      if (!isInClient()) return null
      const value = sessionStorage.getItem(getKey(key, options))
      return value && isJSON(options) ? JSON.parse(value) : value
    },
    has: (key, options) => !!(key && sessionStorage.getItem(getKey(key, options))),
    set: (key, data, options) => {
      sessionStorage.setItem(
        getKey(key, options),
        isJSON(options) ? (data as string) : JSON.stringify(data),
      )
    },
    remove: (key, options) => {
      sessionStorage.removeItem(getKey(key, options))
    },
    clearAll: cb => {
      sessionStorage.clear()

      if (typeof cb === 'function') {
        const id = setTimeout(() => {
          cb()
          clearTimeout(id)
        }, 500)
      }
    },
    length: () => sessionStorage.length,
  })

  // Return the proxy instance for intercepting the get method to check if it's in client side
  return isInClient()
    ? new Proxy(instance, {
        get: (target, prop, receiver) => Reflect.get(target, prop, receiver),
      })
    : instance
}

export { createSessionStorageInstance }
