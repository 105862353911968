import type { ProductType } from '../../../../../../../types'
import Link from 'next/link'
import { SHOP_PATH } from '../../../../../../../utils/routes'
import { Image } from '../../../../../../../components/Image'

const CardImage = ({
  materialId,
  attributes: { name, shopCoverImageUrl },
  isHorizontal,
  imagePriority,
}: {
  materialId: string
  attributes: ProductType.Data['attributes']
  isHorizontal?: boolean
  imagePriority?: boolean
}) => {
  return (
    <Link
      href={SHOP_PATH.DETAIL({ productId: materialId })}
      className='relative block aspect-video w-full'
    >
      <Image
        priority={imagePriority}
        fill
        src={shopCoverImageUrl}
        alt={name}
        className='rounded-lg object-cover'
        sizes={isHorizontal ? '(min-width: 768px) 209px, 100px' : '258px'}
      />
    </Link>
  )
}

export default CardImage
