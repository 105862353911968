import type { Feedbacks as FeedbacksType } from '../../../../types'
import { useState } from 'react'
import { Autoplay, Pagination } from 'swiper'
import FeedbackModal from '../../../../components/modal/feedback-modal'
import HorizontalSwiper from '../../../../components/horizontal-swiper'
import Feedback from './feedback'

const Feedbacks = ({ feedbacks }: { feedbacks: FeedbacksType.Data[] }) => {
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbacksType.Data | null>(null)

  if (!feedbacks.length) return null

  return (
    <div className='pb-[14px] pt-[20px]'>
      <HorizontalSwiper
        cssMode
        updateOnWindowResize
        centeredSlides
        watchSlidesProgress
        grabCursor
        draggable
        loop
        showArrows={false}
        modules={[Autoplay, Pagination]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: true }}
        slideClassName='max-h-[453.6px] w-full overflow-hidden rounded-[20px] px-[15px] mobileUp:!w-auto'
      >
        {feedbacks.map((feedback, index) => {
          return (
            <Feedback
              key={`${index}-${feedback.id}`}
              feedback={feedback}
              onClick={() => setSelectedFeedback(feedback)}
            />
          )
        })}
      </HorizontalSwiper>

      {selectedFeedback && (
        <FeedbackModal
          onClose={() => setSelectedFeedback(null)}
          activeFeedback={selectedFeedback}
        />
      )}
    </div>
  )
}

export { Feedbacks }
