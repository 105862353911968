import type { TFunction } from 'next-i18next'
import { isProd } from '@/libs/env'
import safelyParseJson from '@libs-components/utils/safely-parse-json'

const getIsVariantsListAndPurchaseButtonVisible = (t: TFunction, productId: string) => {
  return !safelyParseJson<string[]>(
    t(
      `crowdin:product-detail-page.hide-variants-list-and-purchase-button-product-ids${
        isProd ? '' : '-staging'
      }`,
    ),
    [],
  ).includes(productId)
}

export default getIsVariantsListAndPurchaseButtonVisible
