import type { PageComponentProps } from '../types'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'next-i18next'
import { Skeleton } from '../../../../components/skeleton'
import { Icon } from '../../../../components/icon'
import { Text } from '../../../../components/text'

type BrandBasicOverviewInfoType = Pick<
  NonNullable<PageComponentProps<'brand_basic_info'>['meta']>,
  'materialCount' | 'totalStudentsCount' | 'averageRating' | 'ratingCount'
>

type OverviewProps = Partial<BrandBasicOverviewInfoType> & {
  className?: string
  showSkeleton?: boolean
  onReviewClick?: () => void
}

const Overview = ({
  materialCount,
  totalStudentsCount,
  averageRating,
  ratingCount,
  className,
  showSkeleton,
  onReviewClick,
}: OverviewProps) => {
  const { t } = useTranslation()
  const hasMaterialCount = Number.isFinite(materialCount)
  const hasStudentCount = Number.isFinite(totalStudentsCount)
  const hasRatingCount = Number.isFinite(averageRating) && Number.isFinite(ratingCount)
  const hasOverview = hasMaterialCount || hasStudentCount || hasRatingCount

  if (!(hasOverview || showSkeleton)) {
    return null
  }

  return (
    <div className={twMerge('flex items-center gap-x-8', className)}>
      {(hasMaterialCount || showSkeleton) && (
        <Text component='div' variant='body_m_400' color='grayscale-400'>
          {t('crowdin:brand-page.basic-info.material-count')}
          {hasMaterialCount ? (
            <Text component='p' variant='body_m_400' color='primary-700'>
              {materialCount}
            </Text>
          ) : (
            showSkeleton && <Skeleton className='h-6' />
          )}
        </Text>
      )}

      {(hasStudentCount || showSkeleton) && (
        <Text component='div' variant='body_m_400' color='grayscale-400'>
          {t('crowdin:brand-page.basic-info.student-count')}
          {hasStudentCount ? (
            <Text component='p' variant='body_m_400' color='primary-700'>
              {totalStudentsCount}
            </Text>
          ) : (
            showSkeleton && <Skeleton className='h-6' />
          )}
        </Text>
      )}

      {(hasRatingCount || showSkeleton) && (
        <Text
          component='div'
          variant='body_m_400'
          color='grayscale-400'
          className={twMerge(onReviewClick && 'cursor-pointer')}
          onClick={onReviewClick}
        >
          {t('crowdin:common.user-feedbacks.review')}
          {hasRatingCount ? (
            <Text
              component='p'
              variant='body_m_400'
              color='primary-700'
              className='flex items-center justify-center'
            >
              {averageRating && averageRating.toFixed(1)}
              <Icon type='star_filled' color='primary-700' />({ratingCount})
            </Text>
          ) : (
            showSkeleton && <Skeleton className='h-6' />
          )}
        </Text>
      )}
    </div>
  )
}

export { Overview, type OverviewProps }
