import ErrorNotifier from '@libs-components/utils/error-notifier'

const RN_WEBVIEW_NOT_AVAILABLE = 'ReactNativeWebView is not available'

const postRnWebviewMessage = (value: any) => {
  try {
    if (!window?.ReactNativeWebView) {
      throw new Error(RN_WEBVIEW_NOT_AVAILABLE)
    }

    const jsonValue = JSON.stringify(value)
    window.ReactNativeWebView.postMessage(jsonValue)
  } catch (err) {
    if ((err as any)?.message === RN_WEBVIEW_NOT_AVAILABLE) {
      ErrorNotifier.notify({
        err,
        context: { key: RN_WEBVIEW_NOT_AVAILABLE, value },
      })

      return
    }

    ErrorNotifier.notify({
      err,
      context: { key: 'Error serializing value', value },
    })
  }
}

export default postRnWebviewMessage

export const POST_RN_WEBVIEW_MESSAGE_TYPES = {
  CLOSE: 'CLOSE',
  TO_APP_MATERIALS: 'TO_APP_MATERIALS',
  RECOMMENDATION_FILTERS: 'recommendation_filters',
  OPEN_TRIAL: 'OPEN_TRIAL',
} as const
