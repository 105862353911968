import type { PropsWithChildren, CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

type SkeletonProps = PropsWithChildren<{ className?: string; style?: CSSProperties }>

const Skeleton = ({ children, className, style }: SkeletonProps) => {
  return (
    <div style={style} className={twMerge('bg-grayscale-100 animate-pulse rounded-lg', className)}>
      {children}
    </div>
  )
}

export { Skeleton }
