import type { ProductType } from '../../../../../../../types'
import { twMerge } from 'tailwind-merge'
import dynamic from 'next/dynamic'
import CardImage from '../shared/CardImage'
import Publisher from '../shared/Publisher'
import Labels from '../shared/Labels'
import Categories from '../shared/Categories'
import Prices from '../shared/Prices'
const RatingDesc = dynamic(import('../shared/RatingDesc'))
const Countdown = dynamic(import('../shared/Countdown'))

const BORDER_BOTTOM_START_AT_FOURTH = [
  '[&>:nth-child(n+4):not(:last-child)]:border-0',
  '[&>:nth-child(n+4):not(:last-child)]:border-b',
  '[&>:nth-child(n+4):not(:last-child)]:border-solid',
  '[&>:nth-child(n+4):not(:last-child)]:border-grayscale-100',
  '[&>:nth-child(n+4)]:pb-2',
]

const ProductCard = ({
  imagePriority,
  product,
  showPrices,
}: {
  imagePriority?: boolean
  product: ProductType.Data
  showPrices?: boolean
}) => {
  const attributes = product?.attributes || {}
  const { countdown } = attributes
  return (
    <div
      data-testid='product-card-vertical'
      className={twMerge('relative flex w-[258px] flex-col gap-2', BORDER_BOTTOM_START_AT_FOURTH)}
    >
      <CardImage imagePriority={imagePriority} materialId={product?.id} attributes={attributes} />
      <Labels attributes={attributes} />
      <Publisher materialId={product?.id} attributes={attributes} />
      <Categories attributes={attributes} />
      {showPrices && <Prices attributes={attributes} />}
      {countdown?.style === 'crowdfunding' ? (
        <Countdown attributes={attributes} />
      ) : (
        <RatingDesc attributes={attributes} />
      )}
    </div>
  )
}

export default ProductCard
