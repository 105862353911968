import type { PropsWithChildren, ComponentProps } from 'react'
import dynamic from 'next/dynamic'
import { twMerge } from 'tailwind-merge'
import { SwiperWithTitleAndControlsSkeleton } from '../../../../components/horizontal-swiper/variants/swiper-with-title-and-controls/skeleton'

const SectionTitle = dynamic(import('../shared/section-title').then(mod => mod.SectionTitle))
const HorizontalSwiper = dynamic(
  import('../../../../components/horizontal-swiper/variants/swiper-with-title-and-controls'),
  { loading: SwiperWithTitleAndControlsSkeleton },
)

type PageComponentSwiperWrapperProps = PropsWithChildren<
  ComponentProps<typeof HorizontalSwiper> & {
    isMultiple: boolean
    singleModeClassName?: string
    showSkeleton?: boolean
  }
>

const PageComponentSwiperWrapper = ({
  isMultiple,
  singleModeClassName,
  title,
  description,
  children,
  showSkeleton,
  ...props
}: PageComponentSwiperWrapperProps) => {
  return isMultiple ? (
    <HorizontalSwiper
      tag='ul'
      title={title}
      description={description}
      pagination={false}
      spaceBetween={16}
      showSkeleton={showSkeleton}
      cssMode
      {...props}
    >
      {children}
    </HorizontalSwiper>
  ) : (
    <div className={twMerge('relative text-center', singleModeClassName)}>
      <SectionTitle title={title} description={description} showSkeleton={showSkeleton} />
      {children}
    </div>
  )
}

export { PageComponentSwiperWrapper }
