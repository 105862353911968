import type { PageComponentType } from '@libs-components/types'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'

import { Feedbacks as UserFeedbacksSection } from '@libs-components/features/page-components/components/user-feedback/feedbacks'
import { Footer } from '@wordup/lib-shop/components'
import { PartnerBrandsSection } from './sections/partner-brands-section'
import KeywordsSection from './sections/KeywordsSection'
import ProductsSection from './sections/ProductsSection'
import QuantitativeDataSection from './sections/quantitative-data-section'
import SearchBarSection from './sections/search-bar-section'
import SubjectsSection from './sections/SubjectsSection'
import HeroSection from './sections/hero-section'
import RecommendationsSection from './sections/RecommendationsSection'
import useInit from './use-init'

const CampaignsSection = dynamic(import('./sections/CampaignsSection'), {
  ssr: false,
  loading: () => <div className='h-[287px]' />,
})
const ModulesSection = dynamic(import('./sections/modules-section'), { ssr: false })
const FaqSection = dynamic(import('./sections/FaqSection'), { ssr: false })
const PartnersSection = dynamic(import('./sections/partners-section'), { ssr: false })
const FloatCampaign = dynamic(
  import('@libs-components/features/page-components/components/float-campaign').then(
    ({ FloatCampaign }) => FloatCampaign,
  ),
  { ssr: false },
)

type Props = {
  pageComponentsResponse: PageComponentType.ResponseType
  isAppHome?: boolean
}

const Homepage = ({ pageComponentsResponse, isAppHome }: Props) => {
  const {
    recommendedProducts,
    latestCourses,
    limitedTimeOfferProducts,
    feedbacks,
    floatCampaign,
    latestEvents,
    subscriptionProducts,
    ebookProducts,
    brandInfo,
  } = useInit({ pageComponents: pageComponentsResponse.data })
  const { t } = useTranslation()
  const recommendedProductsSectionTitle = t('crowdin:home-page.recommended-products-section-title')
  const limitedTimeOfferProductsSectionTitle = t(
    'crowdin:home-page.limited-time-offer-products-section-title',
  )
  const latestProductsSectionTitle = t('crowdin:home-page.latest-products-section-title')
  const subscriptionProductsSectionTitle = t(
    'crowdin:home-page.subscription-products-section-title',
  )
  const ebookProductsSectionTitle = t('crowdin:home-page.ebook-products-section-title')
  const brandInfosSectionTitle = t('crowdin:home-page.brand-infos-section-title')

  return (
    <div className='relative'>
      {!isAppHome && <HeroSection />}

      <SearchBarSection />
      <KeywordsSection />
      <SubjectsSection />

      {!isAppHome && <QuantitativeDataSection />}

      {!isAppHome && <UserFeedbacksSection feedbacks={feedbacks} />}

      <ProductsSection title={recommendedProductsSectionTitle} products={recommendedProducts} />
      <ProductsSection title={ebookProductsSectionTitle} products={ebookProducts} />
      <ProductsSection title={subscriptionProductsSectionTitle} products={subscriptionProducts} />
      <CampaignsSection latestEvents={latestEvents} />
      <PartnerBrandsSection
        title={brandInfosSectionTitle}
        brandInfo={brandInfo}
        meta={pageComponentsResponse.meta}
      />
      <ProductsSection
        title={limitedTimeOfferProductsSectionTitle}
        products={limitedTimeOfferProducts}
        filterSalePriceEndedProducts
      />
      <ProductsSection title={latestProductsSectionTitle} products={latestCourses} />

      {isAppHome && <div className='h-8' />}

      {!isAppHome && (
        <>
          <ModulesSection />
          <FaqSection />
          <PartnersSection />
          <Footer />
        </>
      )}

      {floatCampaign && <FloatCampaign custom={floatCampaign} />}

      <RecommendationsSection />
    </div>
  )
}

export default Homepage
