import type { PageComponentType } from '@libs-components/types'
import { PageComponentImpl } from '@libs-components/features/page-components/components'

type PartnerBrandsSectionProps = {
  title: string
  meta: PageComponentType.ResponseType['meta']
  brandInfo?: PageComponentType.DataType<'picked_brand_page_list'>
}

const PickedBrandPageList = PageComponentImpl.picked_brand_page_list

const PartnerBrandsSection = ({ title, meta, brandInfo }: PartnerBrandsSectionProps) => {
  return <PickedBrandPageList {...brandInfo?.attributes} name={title} meta={meta} />
}

export { PartnerBrandsSection }
