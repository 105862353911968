import { Feedbacks } from '../../types'
import Link from 'next/link'
import { useMemo } from 'react'
import { SHOP_PATH } from '../../utils/routes'
import { Image } from '../Image'
import { Text } from '../text'
import { iso8601StrToFormat } from '../../utils/time'
import { randomUserImage } from '../reviews/constants'
import UserInfoBar from '../reviews/user-info-bar'
import { WuModal } from './Base'

type Props = {
  onClose: () => void
  activeFeedback: Feedbacks.Data
}

const FeedbackModal = ({ onClose, activeFeedback }: Props) => {
  const materialId = activeFeedback.id
  const { userName, createdAt, rating, content } = activeFeedback.attributes || {}
  const randomUserImgUrl = useMemo(
    () => randomUserImage[Math.floor(Math.random() * randomUserImage.length)],
    [],
  )

  return (
    <WuModal open padding='1.25rem 1rem 1rem 1rem' maxWidth='500px' onClose={onClose}>
      <div className='hide-scrollbar flex max-h-[475px] flex-col gap-2 overflow-y-scroll'>
        <UserInfoBar
          userName={userName}
          imgUrl={randomUserImgUrl}
          createdAt={createdAt ? iso8601StrToFormat(createdAt) : ''}
          rating={rating ?? 0}
        />
        <Text variant='body_m_400' className='whitespace-pre-wrap'>
          {content}
        </Text>
        {activeFeedback.product && (
          <div className='bg-background-light flex rounded-lg p-2'>
            <Image
              src={activeFeedback.product.attributes.shopCoverImageUrl}
              alt='feedback modal material'
              width={75}
              height={42}
              className='mr-2 max-w-full rounded-[4px] object-cover'
            />
            <Link
              href={SHOP_PATH.DETAIL({ productId: materialId })}
              className='decoration-grayscale-500 leading-[0px] underline underline-offset-2'
            >
              <Text variant='label_s_400' color='grayscale-500'>
                {activeFeedback.product.attributes.name}
              </Text>
            </Link>
          </div>
        )}
      </div>
    </WuModal>
  )
}

export default FeedbackModal
