import { useRouter } from 'next/router'
import { FormEvent, useState } from 'react'

import { SHOP_PATH } from '@libs-components/utils/routes'

import Input from '@/components/elements/wu-inputs/input'

const SearchBarSection = () => {
  const router = useRouter()
  const [searchInputValue, setSearchInputValue] = useState('')

  const goToSearchPage = () => {
    const trimmedSearchValue = searchInputValue.trim()
    if (!trimmedSearchValue) return

    router.push({
      pathname: SHOP_PATH.SEARCH_TEMPLATE,
      query: { q: trimmedSearchValue },
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    goToSearchPage()
  }

  return (
    <div className='flex items-center justify-center px-4 pb-[16px] pt-[30px]'>
      <form
        name='search-form'
        onSubmit={handleSubmit}
        className='flex w-full items-center justify-center'
      >
        <Input
          className='mobileUp:w-[250px] w-full'
          onChange={e => setSearchInputValue(e.target.value)}
          placeholder='多益'
          suffixIcon='search'
          onSuffixIconClick={goToSearchPage}
        />
      </form>
    </div>
  )
}

export default SearchBarSection
