import { isWuAppWebview } from '@libs-components/utils/device'
import { useRouter } from '@/hooks/use-router'
import { getStaticProps, type PageProps } from './home/get-static-props'
import Homepage from './home'

const Home = ({ pageComponentsResponse }: PageProps) => {
  const { replace } = useRouter()

  if (isWuAppWebview()) {
    replace('/app-home')
  }

  return <Homepage pageComponentsResponse={pageComponentsResponse} />
}

export { getStaticProps }
export default Home
