import type { CSSProperties, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

const PageComponentWrapper = ({
  style,
  className,
  children,
}: PropsWithChildren<{ style?: CSSProperties; className?: string }>) => {
  return (
    <div
      style={style}
      className={twMerge('mobileUp:px-0 mobileUp:py-5 m-auto max-w-[920px] p-4', className)}
    >
      {children}
    </div>
  )
}

export { PageComponentWrapper }
